<template>
  <v-container>
    <v-card class="mx-auto col-md-9">
      <v-card-title class="d-flex justify-space-around pt-0">
        <h1 class="font-weight-medium text-center">{{ title }}</h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" xs sm="6" md="6">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Código (*):</span
            >
          </v-col>

          <v-col cols="12" xs sm="6" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              :disabled="add ? false : true"
              v-model="item.tpt_cdgo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs sm="6" md="6">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Nombre (*):</span
            >
          </v-col>

          <v-col cols="12" xs sm="6" md="6">
            <v-text-field
              outlined
              dense
              hide-details
              type="text"
              v-model="item.tpt_nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row class="d-flex justify-space-around text-center">
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text blue-bootstrap"
              @click="checForm(true)"
            >
              {{ buttontext }}
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text green-bootstrap"
              @click="checForm(false)"
            >
              Guardar
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn class="mx-3 red-bootstrap white--text" @click="cancel">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    add: true,
    title: "",
    buttontext: "",
    close: false,
    item: {},
  }),
  created() {
    let { id, add } = this.$route.query;
    this.add = String(add) == "true" ? true : false;

    if (this.add) {
      this.title = "Crear tipo de tramite";
      this.buttontext = "Agregar tipo de tramite";
    } else {
      this.title = "Actualizar tipo de tramite";
      this.buttontext = "Actualizar tipo de tramite";
      this.getItem(id);
    }
  },

  methods: {
    getItem(id = "") {
      let uri = "/tipotramites/edit/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    checForm(close) {
      let data = this.item;

      if (!data.tpt_cdgo) {
        this.$emit("msj", { text: "Código de tipo de tramite obligatorio" });
      } else if (!data.tpt_nombre) {
        this.$emit("msj", { text: "Nombre de tipo de tramite obligatorio" });
      } else {
        this.close = close;

        if (this.add) this.createTipoTramite();
        else this.updateTipoTramite();
      }
    },

    createTipoTramite() {
      this.item.cmp_id = String(this.$store.state.company);
      this.axios
        .post("/tipotramites/add", this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", { text: "Registro guardado", estado: "success" });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    updateTipoTramite() {
      let uri = "/tipotramites/update/" + this.item._id;

      this.axios
        .put(uri, this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", {
            text: "Registro actualizado",
            estado: "success",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    cancel() {
      this.$router.push({ name: "DisplayTipoTramite" });
    },
  },
};
</script>